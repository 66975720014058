<template>
  <div>
    <lz-list
      selection
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-checkbox-change="handleCheckboxChange"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm" :disabled="disabled"></lz-form>
    </lz-dialog>
    <lz-dialog
      :dialogBtns="[{ key: 'saveAudit', name: '确定', type: 'primary' }]"
      @dialog-btn-click="handleCommonBtnCLick"
      :width="500"
      title="批量审核"
      ref="lzAuditDialog">
      <lz-form :form="auditForm" :col="1" ref="lzAuditForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';

const audit = [
  { label: '待审核', value: '0' },
  { label: '通过', value: '1' },
  { label: '拒绝', value: '2' },
];

const chargeType = [
  { label: '公对公', value: '1' },
  { label: '客户自充', value: '2' },
  { label: '垫付', value: '3' },
];

const status = [
  { label: '是', value: '1' },
  { label: '否', value: '0' },
];

export default {
  data() {
    return {
      search: [
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '是否审核', type: 'select', fieldName: 'audit', value: '', options: audit },
        { name: '', type: 'daterange', fieldName: 'begin_date||end_date', value: [], width: '300px' },
      ],
      config: {
        url: '/finance/balance/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'selectionNoDisabled', Number(el.audit_status) === 0);
            this.$set(el, 'attachment_id', Array.isArray(el.attachment) ? [] : [el.attachment]);
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '分销商', prop: 'agent_name' },
        { name: '预付金额', prop: 'amount' },
        { name: '账户余额', prop: 'balance' },
        {
          name: '充值方式',
          prop: 'charge_type',
          func: (row) => {
            const target = chargeType.find((r) => r.value === String(row.charge_type));
            return target ? target.label : '';
          },
        },
        { name: '是否结账', prop: 'is_checkout', func: (ops) => (ops.is_checkout ? '是' : '否') },
        { name: '是否开票', prop: 'is_invoice', func: (ops) => (ops.is_invoice ? '是' : '否') },
        { name: '充值时间', prop: 'add_date' },
        {
          name: '状态',
          prop: 'audit_status',
          func: (row) => {
            const target = audit.find((r) => r.value === String(row.audit_status));
            return target ? target.label : '';
          },
        },
        { name: '备注', prop: 'memo' },
        { name: '审批备注', prop: 'audit_memo' },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        {
          name: '代理商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        { fieldName: 'amount', type: 'number', name: '预付金额', value: '', required: true },
        { fieldName: 'add_date', type: 'date', name: '充值时间', value: '', required: true },
        { fieldName: 'charge_type', type: 'select', name: '充值方式', value: '', required: true, options: chargeType },
        { fieldName: 'is_checkout', type: 'select', name: '是否结帐', value: '', required: true, options: status },
        { fieldName: 'is_invoice', type: 'select', name: '是否开票', value: '', required: true, options: status },
        {
          fieldName: 'attachment_id',
          type: 'upload',
          name: '附件',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        { fieldName: 'audit_status', type: 'select', name: '审核状态', value: '', options: audit, hide: true },
        { fieldName: 'audit_memo', type: 'textarea', name: '审核备注', value: '', hide: true, width: '100%' },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [],
      title: '',
      disabled: false,
      values: [],
      auditForm: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.user.info;
    },
  },
  methods: {
    async handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit': {
          const response = await this.$baseHttp.get(`/finance/balance/detail?id=${element.id}`);
          if (!response) return;
          this.$set(response.data, 'attachment_id', Array.isArray(element.attachment) ? [] : [element.attachment]);
          this.handleDialogForm(response.data);
        }
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/finance/balance/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post(params.audit_status !== 0 && params.id ? '/finance/balance/update' : '/finance/balance/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        case 'audit':
          if (this.values.length === 0) {
            this.$message.warning('请选择数据');
            return;
          }
          this.auditForm = [
            { fieldName: 'audit_status', type: 'select', name: '审核状态', value: '', options: audit, required: true },
            { fieldName: 'audit_memo', type: 'textarea', name: '审核备注', value: '', required: false, width: '100%' },
          ];
          this.$refs.lzAuditDialog.dialogVisible = true;
          break;
        case 'saveAudit': {
          const params = this.$refs.lzAuditForm.getValue();
          if (!params) return;
          params.ids = this.values.toString();
          const response = await this.$baseHttp.post('/finance/balance/audit', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzAuditDialog.dialogVisible = false;
        }
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      if (response && response.audit_status !== 0 && (this.info.role_id !== 1)) {
        this.disabled = true;
        this.dialogBtns = [];
      } else {
        this.disabled = false;
        this.dialogBtns = [{ key: 'save', name: '保存', type: 'primary' }];
      }
      if (response && response.btn_edit === 1 && response.audit_status !== 0 && this.info.role_id !== 1) {
        this.dialogBtns = [{ key: 'save', name: '保存', type: 'primary' }];
        this.disabled = false;
        this.form.forEach((el) => {
          if (!['is_checkout', 'is_invoice'].includes(el.fieldName)) {
            this.$set(el, 'disabled', true);
          }
        });
      }
      this.form.filter((r) => ['audit_status', 'audit_memo'].includes(r.fieldName)).forEach((el) => {
        this.$set(el, 'hide', !this.disabled);
      });
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {
            add_date: dayjs().format('YYYY-MM-DD'),
          },
        });
      });
    },
    handleCheckboxChange(values) {
      this.values = values.map((r) => r.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
